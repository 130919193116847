<div class="login-cover">
  <div class="login-cover-image" style="background-image: url(../assets/img/cover/home-cover.jpg)"
    data-id="login-cover-image"></div>
  <div class="login-cover-bg-purple"></div>
</div>

<div class="btn-corner p-10">
  <a routerLink="/login" routerLinkActive="active" class="btn btn-l-red">
    <i class="fas fa-lock text-white"></i> &nbsp; Iniciar sesión
  </a>
</div>

<div class="container">
  <div class="row" style="padding-top: 10%;">
    <div class="col-xl-12 col-lg-12" style="text-align: center">
      <img style="margin: 20;" src="https://defensorialg.com.co/wp-content/uploads/2020/03/logodcflg-3.png" />
    </div>
  </div>

  <div class="row" style="padding-top: 5%;">
    <div class="col-xl-12 col-lg-12" style="text-align: center">
      <h1 class="text-white m-t-10">Bienvenido</h1>
      <h3 class="text-white m-t-10">Indícanos qué te gustaría hacer </h3>
    </div>
  </div>

  <div class="row" style="padding-top: 10%;">    
    <div class="col-xl-6 col-lg-6">
      <a href="javascript:;" (click)="open(modalDialog)" class="widget-card widget-card-rounded m-b-20"
        data-id="widget">
        <div class="widget-card-cover"></div>
        <div class="widget-card-content">
          <b class="text-black">Click aquí para sección de peticiones.</b>
        </div>
        <div class="widget-card-content bottom">
          <i class="fab fa-pushed fa-5x text-red"></i>
          <h4 class="text-black m-t-10"><b>NUEVA SOLICITUD</b></h4>
          <h5 class="f-s-12 text-black-transparent-7 m-b-2"><b>DESEO REALIZAR MI SOLICITUD</b></h5>
        </div>
      </a>
    </div>
    <div class="col-xl-6 col-lg-6">
      <a href="javascript:;" routerLink="/login" [queryParams]="{ 'role': 'moanful' }" routerLinkActive="active"
        class="widget-card widget-card-rounded m-b-20" data-id="widget">
        <div class="widget-card-cover"></div>
        <div class="widget-card-content">
          <b class="text-black">Click aquí para consultar tus peticiones.</b>
        </div>
        <div class="widget-card-content RIGHT">
          <i class="fas fa-search fa-5x text-red"></i>
          <h4 class="text-black m-t-10"><b>CONSULTAR SOLICITUD</b></h4>
          <h5 class="f-s-12 text-black-transparent-7 m-b-2"><b>DESEO SABER EL ESTADO DE MI SOLICITUD</b></h5>
        </div>
      </a>
    </div>
   
  </div>
</div>

<ng-template #modalDialog let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Selecciona una entidad para radicar una solicitud</h4>
    <button type="button" class="close" (click)="c('Cross click')">×</button>
  </div>
  <div class="modal-body">
    <div id="EntitiesContent" class="container">
      <div class="row">
        <div class="thumbnail-container" *ngFor="let item of entities$ | async">
          <div class="thumbnail thumbnail-entity m-5" [ngClass]="{'thumbnail-active': item.id==selectedId}"
            (click)="setActive(item.id)" [title]="item.name">
            <img style="width: 90%; margin: -10px 0;" [src]="item.logo" [alt]="item.name">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button (click)="c('Close click')" class="btn btn-default">Cancelar</button>
    <button (click)="goToComplaint()" class="btn btn-success">Continuar con la solicitud</button>
  </div>
</ng-template>